<template>
  <div>
    <h3>評価対象スタッフ</h3>
    <ul class="members">
      <li
        v-for="(item, index) in staffList" :key="index"
        :class="{ current: parseInt(item.userid) === parseInt($route.params.id) }"
      >
        <div @click="StaffDetailView(item.userid)">
          <span>{{ item.name }}</span><i>{{ item.belong }}</i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      staffList: []
    }
  },
  mounted () {
    if (this.$store.getters['Auth/authorized']) {
      this.$store.dispatch('Manager/loadMembers')
      this.$store.dispatch('Manager/loadNeedEvalList')
    }
    this.$store.watch(
      (state, getters) => getters['Auth/authorized'],
      (newValue, oldValue) => {
        if (!oldValue && newValue) {
          this.$store.dispatch('Manager/loadMembers')
          this.$store.dispatch('Manager/loadNeedEvalList')
        }
      }
    )
    if (this.$store.getters['Manager/staffList']) {
      this.staffList = this.$store.getters['Manager/staffList']
    }
    this.$store.watch(
      (state, getters) => getters['Manager/staffList'],
      (newValue) => {
        this.staffList = newValue
      }
    )
  },
  methods: {
    StaffDetailView (id) {
      this.$store.commit('evalmode', true)
      this.$store.dispatch('Staff/clearData')
      this.$store.dispatch('Staff/loadDetail', id)
      this.$router.push({ name: 'StaffDetail', params: { dom: this.$store.getters.domain, id: id } })
    }
  }
}
</script>
