<template>
  <nav id="leftnav">
    <h1><router-link :to="{ name: 'home' }"><img src="@/assets/logo_cocomira.svg"></router-link></h1>
    <template v-if="authorized">
      <div v-if="capability === 'superadmin'">
        <ul>
          <li><router-link :to="{ name: 'AdminDashboard' }">登録企業リスト</router-link></li>
          <li v-if="!slug"><router-link :to="{ name: 'AddCompany' }">＋企業追加</router-link></li>
        </ul>
      </div>
      <div v-if="capability === 'manager'">
        <ul>
          <li v-if="$route.name !== 'CompanyDashboard'">
            <router-link :to="{ name: 'home' }"><i class="fa-solid fa-house"></i>HOMEに戻る</router-link>
          </li>
          <li v-if="capability === 'manager'">
            <router-link :to="{ name: 'TeamMembers', params: { dom: slug } }"><i class="fa-solid fa-user-group"></i>メンバー評価</router-link>
          </li>
        </ul>
      </div>
      <div v-if="capability === 'superadmin' || capability === 'owner'">
        <ul v-if="slug">
          <li><router-link :to="{ name: 'CompanyDashboard', params: { dom: slug } }">ダッシュボード</router-link></li>
          <li><router-link :to="{ name: 'StaffList', params: { dom: slug } }">社員</router-link></li>
          <li><router-link :to="{ name: 'StaffGroup', params: { dom: slug } }">評価グループ</router-link></li>
          <li><router-link :to="{ name: 'StaffMaster', params: { dom: slug } }">社員マスター</router-link></li>
        </ul>
      </div>
      <ul v-else>
        <li><router-link :to="{ name: 'EvalTeikiList', params: { dom: slug } }">定期評価</router-link></li>
        <li><router-link :to="{ name: 'EvalBonusList', params: { dom: slug } }">賞与評価</router-link></li>
        <li><router-link :to="{ name: 'SelfexamList', params: { dom: slug } }">自己評価</router-link></li>
        <li>
          <router-link :to="{ name: 'OneOnOne', params: { dom: slug } }">
            <i class="fa-solid fa-comments"></i>１on１
          </router-link>
        </li>
      </ul>
      <ul>
        <li v-if="restorable"><span @click="restoreMasterLogin">管理者に戻る</span></li>
        <li><span @click="logout">Logout</span></li>
      </ul>
    </template>
    <template v-else>
      <ul>
        <li><router-link :to="{ name: 'Login' }">Login</router-link></li>
      </ul>
    </template>
  </nav>
  <main>
    <PageHeading :mode="mode" />
    <div>
      <router-view :mode="mode" />
    </div>
  </main>
  <aside>
    <AsideNavi :mode="mode" />
  </aside>
  <ModalWindow v-if="modal"></ModalWindow>
</template>

<script>
import PageHeading from '@/components/PageHeading.vue'
import AsideNavi from '@/components/AsideNavi.vue'
import ModalWindow from '@/components/ModalWindow.vue'
export default {
  components: {
    PageHeading,
    AsideNavi,
    ModalWindow
  },
  data () {
    return {
      currentVersion: require('../package.json').version,
      authorized: false,
      capability: '',
      modal: false,
      mode: this.$store.getters.mode,
      slug: this.$store.getters.slug,
      restorable: false
    }
  },
  mounted () {
    this.confirmVersion()
    // authorized
    this.authorized = this.$store.getters['Auth/authorized']
    this.$store.watch(
      (state, getters) => getters['Auth/authorized'],
      (newValue, oldValue) => {
        this.authorized = newValue
      }
    )
    // slug
    if (this.$store.getters.slug) {
      this.slug = this.$store.getters.slug
    }
    this.$store.watch(
      (state, getters) => getters.slug,
      (newValue) => {
        this.slug = newValue
      }
    )
    // capability
    this.capability = this.$store.getters['Auth/capability']
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.capability = newValue
      }
    )
    // Processing表示
    this.$store.watch(
      (state, getters) => getters.processing,
      (newValue) => {
        this.processing = newValue
        const body = document.querySelector('body')
        if (newValue > 0) {
          body.classList.add('processing')
        } else {
          body.classList.remove('processing')
        }
      }
    )
    // Modal
    this.$store.watch(
      (state, getters) => getters['Modal/show'],
      (newValue) => {
        this.modal = newValue
      }
    )
    // Mode
    this.$store.watch(
      (state, getters) => getters.mode,
      (newValue) => {
        this.mode = newValue
      }
    )
    // restorable
    this.restorable = this.$store.getters['Auth/authDataBackup'] ? 1 : 0
    this.$store.watch(
      (state, getters) => getters['Auth/authDataBackup'],
      (newValue) => {
        this.restorable = newValue ? 1 : 0
      }
    )
  },
  methods: {
    //
    // バージョン確認
    confirmVersion () {
      const versionCookies = this.$cookies.get('versionCookies')
      if (versionCookies === null) {
        // versionが存在しない場合
        // 現在のversionをセットして再読み込み
        this.$cookies.set('versionCookies', this.currentVersion, '30d')
        window.location.reload(true)
      } else {
        // versionが存在する場合
        if (versionCookies !== this.currentVersion) {
          // versionCookiesがcurrentVersionと不一致ならば
          // 現在のversionをセットして再読み込み
          this.$cookies.set('versionCookies', this.currentVersion, '30d')
          window.location.reload(true)
        }
      }
    },
    logout () {
      this.$store.dispatch('Auth/logout')
    },
    setMeta (route) {
      if (route.meta.title) {
        const setTitle = route.meta.title + ' | COCOMIRA人事評価'
        document.title = setTitle
      }
    },
    setMode (str) {
      this.$store.commit('mode', str)
    },
    restoreMasterLogin () {
      this.$store.dispatch('Auth/restoreLogin')
    }
  },
  computed: {
  },
  watch: {
    '$route' (route, from) {
      this.setMeta(route)
    }
  }
}
</script>
