<template>
  <div @click.stop class="setevalrange">
    <h5>{{ title }}対象期間を設定してください</h5>
    <div class="evalrange">
      <div :class="{ error: !state.from }">
        <input type="date" v-model="from">
        <p>日付を指定してください</p>
      </div>
      <span>〜</span>
      <div :class="{ error: !state.to }">
        <input type="date" v-model="to">
        <p>日付を指定してください</p>
      </div>
    </div>
    <div class="sleeve">
      <button type="cancel" @click="$emit('close')">キャンセル</button>
      <button @click="placeRequest">OK</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      title: '',
      from: '',
      to: '',
      state: {
        from: true,
        to: true
      }
    }
  },
  mounted () {
    if (this.$store.getters['Modal/mode'] === 'periodical') {
      this.title = '【定期評価】'
    }
    if (this.$store.getters['Modal/mode'] === 'bonus') {
      this.title = '【賞与評価】'
    }
  },
  methods: {
    //
    // 評価リクエスト有効化
    async placeRequest () {
      if (this.chkFilled()) {
        this.$store.commit('processing', true)
        return new Promise((resolve) => {
          axios
            .post('/wp-json/jjs/v2/eval/request', {
              cat: this.$store.getters['Modal/mode'],
              range: [this.from, this.to]
            })
            .then((response) => {
              console.log(response.data)
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              this.$store.commit('processing', false)
              this.$store.commit('updated', true)
              this.$store.dispatch('Company/loadData')
              this.$emit('close')
            })
        })
      }
    },
    chkFilled () {
      this.state.from = Boolean(this.from)
      this.state.to = Boolean(this.to)
      return this.state.from && this.state.to
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
