<template>
  <div>
    <div class="gradeindicator">
      <h5>現在のポイント</h5>
      <div class="point" :class="{ enough: enough }">
        <em>{{ point }}</em><i>/{{ targetPoint }}</i>
      </div>
    </div>
    <div class="staffnavi">
      <h5 @click="toggleShow">
        評価基準
        <i class="fa-solid fa-caret-up" v-if="show"></i>
        <i class="fa-solid fa-caret-down" v-else></i>
      </h5>
      <ul v-if="show">
        <li @click="setMode('role')" :class="{ current: mode === 'role' }"><span>役割定義</span></li>
        <li @click="setMode('raise')" :class="{ current: mode === 'raise' }"><span>昇格・昇給</span></li>
        <li @click="setMode('salary')" :class="{ current: mode === 'salary' }"><span>給与</span></li>
        <li @click="setMode('evalTeiki')" :class="{ current: mode === 'evalTeiki' }"><span>定期評価</span></li>
        <li @click="setMode('evalBonus')" :class="{ current: mode === 'evalBonus' }"><span>賞与評価</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ['mode'],
  data () {
    return {
      companyData: {},
      staffData: {},
      show: false
    }
  },
  mounted () {
    if (this.$store.getters['Staff/staffData']) {
      this.staffData = this.$store.getters['Staff/staffData']
    }
    this.$store.watch(
      (state, getters) => getters['Staff/staffData'],
      (newValue) => {
        this.staffData = newValue
      }
    )
    if (this.$store.getters['Company/data']) {
      this.companyData = this.$store.getters['Company/data']
    }
    this.$store.watch(
      (state, getters) => getters['Company/data'],
      (newValue) => {
        this.companyData = newValue
      }
    )
  },
  methods: {
    setMode (str) {
      this.$store.commit('mode', str)
      this.$router.push({ name: 'CompanyDefs', params: { dom: this.$store.getters['Auth/domain'] } })
    },
    toggleShow () {
      this.show = !this.show
    }
  },
  computed: {
    point () {
      let point = this.staffData.point
      if (!point || point === undefined) {
        point = 0
      }
      return point
    },
    targetPoint () {
      if (this.companyData && 'settings' in this.companyData) {
        const arr = this.companyData.settings.classdefs.point
        const cls = this.staffData.class
        return arr && cls ? arr[parseInt(cls) - 1] : 0
      } else {
        return false
      }
    },
    enough () {
      return parseInt(this.point) !== 0 && parseInt(this.point) >= parseInt(this.targetPoint)
    }
  },
  watch: {
  }
}
</script>
