import axios from 'axios'
import router from '@/router'

const state = {
  id: false,
  temporary: false,
  cat: '',
  qanda: [
    {
      question: '1.今期、貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
      answer: ''
    },
    {
      question: '2.今期改善が必要な事項、結果が出せなかったこと等',
      answer: ''
    },
    {
      question: '3.対象期間において自分の仕事を下記の項目ごとに５段階で評価すると',
      answer: []
    },
    {
      question: '4.今後の課題、目標',
      answer: ''
    },
    {
      question: '5.その他仕事での気づき、意見',
      answer: ''
    }
  ],
  qandaBonus: [
    {
      question: '1.ここまでの貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
      answer: ''
    },
    {
      question: '2.ここまでで改善が必要な事項、結果が出せなかったこと等',
      answer: ''
    },
    {
      question: '3.今後の課題、目標',
      answer: []
    },
    {
      question: '4.その他仕事での気づき、意見',
      answer: ''
    }
  ],
  hexagonalmap: [],
  list: [],
  date: '',
  range: [],
  meta: [],
  done: false,
  status: ''
}

const getters = {
  id: (state) => state.id,
  temporary: (state) => state.temporary,
  cat: (state) => state.cat,
  qanda: (state) => state.qanda,
  qandaBonus: (state) => state.qandaBonus,
  hexagonalmap: (state) => state.hexagonalmap,
  list: (state) => state.list,
  date: (state) => state.date,
  range: (state) => state.range,
  meta: (state) => state.meta,
  done: (state) => state.done,
  status: (state) => state.status
}

const mutations = {
  id (state, id) {
    state.id = id
  },
  temporary (state, temporary) {
    state.temporary = temporary
  },
  cat (state, cat) {
    state.cat = cat
  },
  qanda (state, qanda) {
    state.qanda = qanda
  },
  qandaBonus (state, qandaBonus) {
    state.qandaBonus = qandaBonus
  },
  hexagonalmap (state, hexagonalmap) {
    state.hexagonalmap = hexagonalmap
    state.qanda[2].answer = hexagonalmap
  },
  list (state, list) {
    state.list = list
  },
  date (state, date) {
    state.date = date
  },
  range (state, range) {
    state.range = range
  },
  meta (state, meta) {
    state.meta = meta
  },
  done (state, done) {
    state.done = done
  },
  status (state, status) {
    state.status = status
  }
}

const actions = {
  //
  // 提出済みチェック
  chkIfDone ({ getters, commit }, uid) {
    axios
      .get('/wp-json/jjs/v2/selfcheck/chkdone/' + uid)
      .then((response) => {
        console.log(response.data)
        console.log(response.data[getters.cat])
        if (response.data) {
          if ((typeof response.data === 'object') && (getters.cat in response.data)) {
            if (response.data[getters.cat]) {
              commit('id', response.data[getters.cat].selfEvalId)
              commit('status', response.data[getters.cat].status)
              commit('date', response.data[getters.cat].date)
              commit('range', response.data[getters.cat].range)
              commit('qanda', response.data[getters.cat].qanda)
              commit('hexagonalmap', response.data[getters.cat].qanda[2].answer)
              commit('meta', {
                no: response.data[getters.cat].no,
                name: response.data[getters.cat].name,
                belong: response.data[getters.cat].belong,
                section: response.data[getters.cat].section,
                jobtitle: response.data[getters.cat].jobtitle,
                yearcount: response.data[getters.cat].yearcount
              })
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 自己評価リスト
  loadList ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/list')
        .then((response) => {
          // console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価リスト with id
  loadListById ({ getters, commit }, id) {
    if (!id) {
      return
    }
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/list/' + id)
        .then((response) => {
          console.log(response.data)
          commit('list', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価詳細取得
  loadDetail ({ commit }, id) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/' + id)
        .then((response) => {
          console.log(response.data)
          commit('id', id)
          commit('status', response.data.status)
          commit('date', response.data.date)
          commit('range', response.data.range)
          commit('cat', response.data.cat)
          commit('qanda', response.data.qanda)
          commit('hexagonalmap', response.data.qanda[2].answer)
          commit('meta', {
            no: response.data.no,
            userid: response.data.userid,
            name: response.data.name,
            belong: response.data.belong,
            section: response.data.section,
            jobtitle: response.data.jobtitle,
            yearcount: response.data.yearcount
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価保存・一時保存
  save ({ getters, commit }, staffid) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/selfcheck/update', {
          selfChkId: getters.id,
          staffid: staffid,
          tmp: getters.temporary, // true, false
          cat: getters.cat, // periodical, bonus
          range: getters.range,
          qanda: getters.qanda
        })
        .then((response) => {
          console.log(response.data)
          commit('id', response.data)
          if (getters.temporary) {
            commit('status', 'draft')
          } else {
            commit('status', 'publish')
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価保存
  saveSelfCheck ({ getters, commit }, staffid) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/selfcheck/add', {
          id: staffid,
          tmp: getters.temporary, // true, false
          cat: getters.cat, // periodical, bonus
          range: getters.range,
          qanda: getters.qanda
        })
        .then((response) => {
          console.log(response.data)
          alert('自己評価を提出しました')
          router.push({ name: 'StaffDashboard' })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          // commit('Auth/doneSelfchk', true, { root: true })
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 自己評価リクエスト有効化
  async activate ({ getters, commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/selfcheck/activate/', getters.range)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('Staff/loadStaffList', null, { root: true })
        })
    })
  },
  //
  // 自己評価リクエスト無効化
  async inactivate ({ commit, dispatch }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/selfcheck/inactivate')
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Client/loadClientData', null, { root: true })
          dispatch('Staff/loadStaffList', null, { root: true })
        })
    })
  },
  //
  // 評価期間取得
  async getEvalRange ({ commit }, cat) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/selfcheck/evalrange/' + cat)
        .then((response) => {
          console.log(response.data)
          commit('range', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // リセット
  reset ({ commit }) {
    commit('qanda', [
      {
        question: '1.今期、貢献できたこと、また改善できたこと、新たに取り組んだ仕事など',
        answer: ''
      },
      {
        question: '2.今期改善が必要な事項、結果が出せなかったこと等',
        answer: ''
      },
      {
        question: '3.対象期間において自分の仕事を下記の項目ごとに５段階で評価すると',
        answer: []
      },
      {
        question: '4.今後の課題、目標',
        answer: ''
      },
      {
        question: '5.その他仕事での気づき、意見',
        answer: ''
      }
    ])
    commit('hexagonalmap', [])
    commit('list', [])
    commit('date', '')
    commit('range', [])
    commit('meta', [])
  },
  //
  // Clear data
  clearData ({ commit }) {
    commit('id', '')
    commit('temporary', false)
    commit('cat', '')
    commit('hexagonalmap', [])
    commit('list', [])
    commit('date', '')
    commit('range', [])
    commit('meta', [])
    commit('done', false)
    commit('status', '')
  }
}

const SelfExam = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default SelfExam
