import axios from 'axios'

const state = {
  data: ''
}
const getters = {
  data: (state) => state.data
}
const mutations = {
  data (state, data) {
    state.data = data
  }
}
const actions = {
  //
  // クライアント情報取得
  async loadData ({ rootGetters, commit }) {
    console.log('load client whole data')
    return new Promise((resolve) => {
      let processing = rootGetters.processing
      commit('processing', processing + 1, { root: true })
      axios
        .get('/wp-json/jjs/v2/client/wholedata')
        .then((response) => {
          console.log(response.data)
          commit('data', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          // commit('processing', false, { root: true })
          processing = rootGetters.processing
          commit('processing', processing - 1, { root: true })
        })
    })
  },
  //
  // 設定更新
  updateSetting ({ getters, commit }, key) {
    if (key in getters.data.settings) {
      console.log(key)
      console.log(getters.data.settings[key])
      axios
        .patch('/wp-json/jjs/v2/client/settings/update', {
          key: key,
          container: getters.data.settings[key]
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}

const Company = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Company
