import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'HOME' },
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'ログイン' },
    component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      store.commit('companyname', '')
      store.commit('slug', '')
      next()
    }
  },
  {
    // 社労士
    path: '/admin',
    children: [
      {
        // ダッシュボード
        path: '',
        name: 'AdminDashboard',
        meta: { title: '社労士ダッシュボード' },
        component: () => import(/* webpackChunkName: "AdminDashboard" */ '../views/Admin/AdminDashboard.vue'),
        beforeEnter: (to, from, next) => {
          store.commit('companyname', '')
          store.commit('slug', '')
          store.dispatch('Auth/login')
          next()
        }
      },
      {
        // ダッシュボード
        path: 'add',
        name: 'AddCompany',
        meta: { title: '企業新規登録' },
        component: () => import(/* webpackChunkName: "AddCompany" */ '../views/Admin/AddCompany.vue'),
        beforeEnter: (to, from, next) => {
          next()
        }
      }
    ]
  },
  {
    // 経営者ダッシュボード
    path: '/owner',
    name: 'OwnerDashboard',
    meta: { title: '経営者ダッシュボード' },
    component: () => import(/* webpackChunkName: "OwnerDashboard" */ '../views/Owner/OwnerDashboard.vue')
  },
  {
    // 管理職ダッシュボード
    path: '/manager',
    name: 'ManagerDashboard',
    meta: { title: '管理職ダッシュボード' },
    component: () => import(/* webpackChunkName: "ManagerDashboard" */ '../views/Manager/ManagerDashboard.vue')
  },
  {
    // 企業
    path: '/:dom',
    children: [
      {
        // ダッシュボード
        path: '',
        name: 'CompanyDashboard',
        meta: { title: '企業ダッシュボード' },
        component: () => import(/* webpackChunkName: "CompanyDashboard" */ '../views/CompanyDashboard.vue'),
        beforeEnter: (to, from, next) => {
          // if (from.name === 'AdminDashboard' || from.name === 'OwnerDashboard') {
          //   store.commit('slug', to.params.dom)
          //   store.dispatch('Auth/login')
          // }
          store.commit('slug', to.params.dom)
          store.dispatch('Auth/login')
          next()
        }
      },
      {
        // 基本設定
        path: 'settings',
        name: 'CompanySettings',
        meta: { title: '企業基本情報' },
        component: () => import(/* webpackChunkName: "SettingsView" */ '../views/Company/SettingsView.vue')
      },
      {
        // 定義
        path: 'defs',
        name: 'CompanyDefs',
        meta: { title: '評価定義' },
        component: () => import(/* webpackChunkName: "DefsView" */ '../views/Company/DefsView.vue')
      },
      {
        // 経営者
        path: 'owner',
        children: [
          {
            // 評価承認
            path: 'approve-promote',
            name: 'ApprovePromote',
            meta: { title: '昇格承認' },
            component: () => import(/* webpackChunkName: "ApprovePromote" */ '../views/Owner/ApprovePromote.vue')
          },
          {
            // 賞与承認
            path: 'paybonus',
            name: 'PayBonus',
            meta: { title: '昇格承認' },
            component: () => import(/* webpackChunkName: "PayBonus" */ '../views/Owner/PayBonus.vue')
          }
        ]
      },
      {
        // 社員
        path: 'staff',
        children: [
          {
            // 社員一覧
            path: '',
            name: 'StaffList',
            meta: { title: '社員一覧' },
            component: () => import(/* webpackChunkName: "StaffList" */ '../views/Company/StaffListView.vue')
          },
          {
            // 評価グループ
            path: 'group',
            name: 'StaffGroup',
            meta: { title: '評価グループ' },
            component: () => import(/* webpackChunkName: "StaffGroup" */ '../views/Company/StaffGroupView.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('Staff/loadStaffList')
              next()
            }
          },
          {
            path: 'master',
            children: [
              {
                // 社員マスター
                path: '',
                name: 'StaffMaster',
                meta: { title: '社員マスター' },
                component: () => import(/* webpackChunkName: "StaffMaster" */ '../views/Company/StaffMasterView.vue'),
                beforeEnter: (to, from, next) => {
                  store.commit('mode', '')
                  next()
                }
              },
              {
                // 一括登録
                path: 'bulkedit',
                name: 'BulkRegistration',
                meta: { title: '社員一括登録' },
                component: () => import(/* webpackChunkName: "BulkRegistration" */ '../views/Company/StaffBulkEdit.vue')
              }
            ]
          },
          {
            // 社員詳細
            path: ':id',
            children: [
              {
                path: '',
                name: 'StaffDetail',
                meta: { title: '社員詳細' },
                component: () => import(/* webpackChunkName: "StaffDetail" */ '../views/Staff/StaffDetailView.vue')
              },
              {
                path: 'eval',
                name: 'EvalPeriodical',
                meta: { title: '定期評価' },
                component: () => import(/* webpackChunkName: "EvalPeriodical" */ '../views/Manager/EvalPeriodical.vue')
              },
              {
                path: 'evalbonus',
                name: 'EvalBonus',
                meta: { title: '賞与評価' },
                component: () => import(/* webpackChunkName: "EvalBonus" */ '../views/Manager/EvalBonus.vue')
              },
              {
                // log
                path: 'log',
                name: 'StaffLog',
                meta: { title: '履歴' },
                component: () => import(/* webpackChunkName: "StaffLog" */ '../views/Staff/LogView.vue')
              },
              {
                // 1on1
                path: '1on1',
                name: 'OneOnOneDialogue',
                meta: { title: '1on1' },
                component: () => import(/* webpackChunkName: "OneOnOneDialogue" */ '../views/Staff/OneOnOneDialogue.vue')
              }
            ]
          }
        ]
      },
      {
        // 評価対象スタッフ一覧
        path: 'members',
        name: 'TeamMembers',
        meta: { title: 'メンバー評価' },
        component: () => import(/* webpackChunkName: "TeamMembers" */ '../views/Manager/TeamMembersView.vue')
      },
      {
        // 自己評価
        path: 'selfexam',
        children: [
          {
            path: '',
            name: 'SelfExam',
            meta: { title: '自己評価' },
            component: () => import(/* webpackChunkName: "SelfExam" */ '../views/Staff/SelfExamView.vue'),
            beforeEnter: (to, from, next) => {
              store.dispatch('SelfExam/clearData')
              next()
            }
          },
          {
            path: 'list',
            name: 'SelfexamList',
            meta: { title: '自己評価一覧' },
            component: () => import(/* webpackChunkName: "SelfexamList" */ '../views/Staff/SelfexamList.vue')
          },
          {
            path: ':id',
            name: 'SelfexamDetail',
            meta: { title: '自己評価詳細' },
            component: () => import(/* webpackChunkName: "SelfexamDetail" */ '../views/SelfexamDetail.vue')
          }
        ]
      },
      {
        // 辞令
        path: 'jirei/:id',
        name: 'Jirei',
        meta: { title: '辞令' },
        component: () => import(/* webpackChunkName: "Jirei" */ '../views/JireiView.vue')
      },
      {
        // 人事評価
        path: 'eval',
        children: [
          {
            // 【定期評価】詳細
            path: 'teiki',
            children: [
              {
                path: '',
                name: 'EvalTeikiList',
                meta: { title: '定期評価一覧' },
                component: () => import(/* webpackChunkName: "EvalTeikiDetail" */ '../views/Staff/EvalTeikiList.vue')
              },
              {
                path: ':id',
                name: 'EvalTeikiDetail',
                meta: { title: '評価詳細' },
                component: () => import(/* webpackChunkName: "EvalTeikiDetail" */ '../views/ShowOnly/SOEvalTeiki.vue')
              }
            ]
          },
          {
            // 【賞与評価】詳細
            path: 'bonus',
            children: [
              {
                path: '',
                name: 'EvalBonusList',
                meta: { title: '賞与評価一覧' },
                component: () => import(/* webpackChunkName: "EvalBonusList" */ '../views/Staff/EvalBonusList.vue')
              },
              {
                path: ':id',
                name: 'EvalBonusDetail',
                meta: { title: '評価詳細' },
                component: () => import(/* webpackChunkName: "EvalBonusDetail" */ '../views/ShowOnly/SOEvalBonus.vue')
              }
            ]
          }
        ]
      },
      {
        // 1on1
        path: '1on1',
        name: 'OneOnOne',
        meta: { title: '１on１' },
        component: () => import(/* webpackChunkName: "OneOnOne" */ '../views/Staff/OneOnOneView.vue')
      },
      // フィードバック
      {
        path: 'feedback/:id',
        name: 'FeedbackDetail',
        meta: { title: 'フィードバック' },
        component: () => import(/* webpackChunkName: "FeedbackDetail" */ '../views/FeedbackSheet.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('pageTitle', '')
  store.commit('titleAdd', '')
  store.commit('titleStatus', '')
  if (to.name !== 'Login' && !store.getters['Auth/token']) {
    store.commit('slug', to.params.dom)
    store.dispatch('Auth/login')
  }
  next()
})

export default router
