<template>
  <header id="pagetitle">
    <div v-if="companyname">
      <h1>{{ companyname }}</h1>
      <h2>{{ pageTitle }}<i v-if="titleStatus">／{{ titleStatus }}</i></h2>
      <h3 v-if="titleAdd">{{ titleAdd }}</h3>
    </div>
    <div v-else>
      <h1>ココミラ人事評価</h1>
    </div>
  </header>
</template>

<script>
export default {
  props: ['mode'],
  data () {
    return {
      companyname: this.$store.getters.companyname,
      pageTitle: this.$store.getters.pageTitle,
      titleAdd: this.$store.getters.titleAdd,
      titleStatus: this.$store.getters.titleStatus,
      cap: this.$store.getters['Auth/capability'],
      tpl: this.$route.name,
      staffName: this.$store.getters['Auth/name']
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.companyname,
      (newValue) => {
        this.companyname = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.pageTitle,
      (newValue) => {
        this.pageTitle = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.titleAdd,
      (newValue) => {
        this.titleAdd = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters.titleStatus,
      (newValue) => {
        this.titleStatus = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/name'],
      (newValue) => {
        this.staffName = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue) => {
        this.cap = newValue
      }
    )
  },
  methods: {
  },
  computed: {
  },
  watch: {
    $route: {
      handler (newValue) {
        this.tpl = newValue.name
      }
    }
  }
}
</script>
