<template>
  <div>
    <ul>
      <li><router-link :to="{ name: 'CompanySettings' }">企業基本情報</router-link></li>
      <li>
        <router-link :to="{ name: 'CompanyDefs' }">評価定義</router-link>
        <ul v-if="$route.name === 'CompanyDefs'">
          <li @click="setMode('domain')" :class="{ current: mode === 'domain' }"><span>部門</span></li>
          <li @click="setMode('role')" :class="{ current: mode === 'role' }"><span>役割</span></li>
          <li @click="setMode('raise')" :class="{ current: mode === 'raise' }"><span>昇格・昇給</span></li>
          <li @click="setMode('salary')" :class="{ current: mode === 'salary' }"><span>給与</span></li>
          <li @click="setMode('initial')" :class="{ current: mode === 'initial' }"><span>初任給</span></li>
          <li @click="setMode('career')" :class="{ current: mode === 'career' }"><span>キャリア採用</span></li>
          <li @click="setMode('allowance')" :class="{ current: mode === 'allowance' }"><span>手当</span></li>
          <li @click="setMode('bonus')" :class="{ current: mode === 'bonus' }"><span>賞与</span></li>
          <li @click="setMode('evalTeiki')" :class="{ current: mode === 'evalTeiki' }"><span>評価シート【定期】</span></li>
          <li @click="setMode('evalBonus')" :class="{ current: mode === 'evalBonus' }"><span>評価シート【賞与】</span></li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['mode'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    setMode (str) {
      this.$store.commit('mode', str)
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
