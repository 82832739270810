<template>
  <div>
    ココミラ人事評価 v2.0.0.1
  </div>
</template>

<script>
export default {
  mounted () {
    if (this.$store.getters['Auth/authorized']) {
      this.navToAppropriateDashboard()
    }
    this.$store.watch(
      (state, getters) => getters['Auth/authorized'],
      (newValue, oldValue) => {
        if (!oldValue && newValue) {
          this.navToAppropriateDashboard()
        }
      }
    )
  },
  methods: {
    navToAppropriateDashboard () {
      const capability = this.$store.getters['Auth/capability']
      switch (capability) {
        case 'superadmin':
          this.$router.push({ name: 'AdminDashboard' })
          break
        case 'owner':
          this.$router.push({ name: 'OwnerDashboard' })
          break
        case 'manager':
          this.$router.push({ name: 'ManagerDashboard' })
          break
        default:
          this.$router.push({ name: 'CompanyDashboard', params: { dom: this.$store.getters['Auth/domain'] } })
      }
    }
  }
}
</script>
