<template>
  <div class="addstaff" @click.stop>
    <h5>社員追加</h5>
    <dl>
      <dt>部署</dt>
      <dd>
        <select v-model="staffData.belong">
          <option value="">--</option>
          <option
            v-for="(item, index) in domains" :key="index"
          >{{ item }}</option>
        </select>
      </dd>
      <dt>課</dt>
      <dd>
        <input type="text" v-model="staffData.section">
      </dd>
      <dt>社員番号</dt>
      <dd :class="{error: states.no === false}">
        <input type="number" v-model="staffData.no" @focus="unsetError('no')" @blur="validate">
        <p class="error">社員番号を入力してください</p>
      </dd>
      <dt>氏名</dt>
      <dd :class="{error: states.nickname === false}">
        <input type="text" v-model="staffData.nickname" @focus="unsetError('nickname')" @blur="validate">
        <p class="error">氏名を入力してください</p>
      </dd>
      <dt>性別</dt>
      <dd class="sex">
        <label :class="{checked: staffData.sex === '男性'}"><span></span><input type="radio" name="sex" value="男性" v-model="staffData.sex">男性</label>
        <label :class="{checked: staffData.sex === '女性'}"><span></span><input type="radio" name="sex" value="女性" v-model="staffData.sex">女性</label>
      </dd>
      <dt>メールアドレス</dt>
      <dd :class="{error: states.email === false, duplicated: duplicatedEmail, invalid: invalidEmailFormat }">
        <input type="email" v-model="staffData.email" @focus="unsetError('email')" @blur="validateEmail">
        <p class="error">メールアドレスを入力してください</p>
        <p class="duplicated" v-if="duplicatedEmail">既に登録されたメールアドレスです</p>
        <p class="invalid" v-if="invalidEmailFormat">不正なメールアドレス形式</p>
      </dd>
      <dt>携帯電話番号</dt>
      <dd :class="{error: states.keitai === false, duplicated: duplicated}">
        <input type="tel" v-model="staffData.keitai" @focus="unsetError('keitai')" @blur="chkDuplicate">
        <p class="error">携帯電話番号を入力してください</p>
        <p class="duplicated">既に登録された携帯電話番号です</p>
      </dd>
      <dt>生年月日</dt>
      <dd><input type="date" v-model="staffData.birth" :max="today" min="1960-01-01"></dd>
      <dt>入社経緯</dt>
      <dd>
        <select v-model="staffData.adoption" @change="getInitialCond">
          <option value="">--</option>
          <option>新卒</option>
          <option>未経験（社会人経験有）</option>
          <option>ポテンシャル/キャリア採用</option>
          <option>スカウト採用</option>
          <option>その他</option>
        </select>
      </dd>
      <dt>最終学歴</dt>
      <dd>
        <select v-model="staffData.graduate" @change="getInitialCond">
          <option value="">--</option>
          <option
            v-for="(item, index) in initialgrade" :key="index"
          >{{ item.label }}</option>
        </select>
      </dd>
      <dt>経験年数</dt>
      <dd>
          <select v-model="staffData.yoe" @change="getInitialCond">
            <option value="">--</option>
            <option
              v-for="num in 23" :key="num"
              :value="num"
            >{{ num }}年</option>
          </select>
      </dd>
      <dt class="classlv">
        <span>等級</span>
        <span><input type="number" v-model="staffData.class" @change="updateSalary"></span>
        <span>号俸</span>
        <span><input type="number" v-model="staffData.lv" @change="updateSalary"></span>
        <span>給与</span>
        <span class="salary">{{ showAsMoneyStr(staffData.salary) }}</span>
      </dt>
      <dt class="last">通知メール</dt>
      <dd class="last notify">
        <label>
          <input type="checkbox" v-model="staffData.notify" />
          <span></span>
          登録通知メールを送信する
        </label>
      </dd>
    </dl>
    <div class="sleeve">
      <button
        @click="addStaff"
      >追加</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import utility from '@/mixins/utility.js'
export default {
  mixins: [utility],
  data () {
    return {
      domains: this.$store.getters['Company/data'].settings.domains,
      initialgrade: this.$store.getters['Company/data'].settings.initialgrade,
      staffData: {
        belong: this.$store.getters['Modal/payload']
      },
      states: {
        no: true,
        nickname: true,
        keitai: true,
        email: true
      },
      duplicated: false,
      duplicatedEmail: false,
      invalidEmailFormat: false,
      filled: false
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters['Modal/error'],
      (newValue) => {
        this.duplicated = newValue
      }
    )
    this.$store.watch(
      (state, getters) => getters['Modal/duplicateEmail'],
      (newValue) => {
        this.duplicatedEmail = newValue
        this.states.email = !newValue
      }
    )
    if (this.$store.getters['Staff/staffList']) {
      this.staffData.no = this.maxStaffNo(this.$store.getters['Staff/staffList'])
    }
  },
  methods: {
    // 社員番号の最大値＋１
    maxStaffNo (list) {
      let staffno = 0
      Object.keys(list).forEach(dom => {
        Object.keys(list[dom]).forEach(staff => {
          staffno = Math.max(staffno, list[dom][staff].id)
        })
      })
      return staffno + 1
    },
    // スタッフ追加
    addStaff () {
      if (this.validate() && !this.duplicated && !this.duplicatedEmail && !this.invalidEmailFormat) {
        this.$store.commit('Staff/staffData', this.staffData)
        this.$store.dispatch('Staff/addStaff')
      }
    },
    validate () {
      let res = true
      Object.keys(this.states).forEach(el => {
        this.states[el] = this.staffData[el] !== '' && this.staffData[el] !== undefined
        res *= this.states[el]
      })
      return res
    },
    chkDuplicate (e) {
      // console.log(e.target.value)
      this.$store.dispatch('Modal/chkDuplicate', e.target.value)
    },
    //
    // Email validation
    validateEmail (e) {
      const email = e.target.value
      if (email) {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
        if (email.match(pattern)) {
          this.invalidEmailFormat = false
          // check duplicate
          this.$store.dispatch('Modal/chkDuplicateEmail', email)
        } else {
          this.invalidEmailFormat = true
        }
      }
    },
    //
    // エラー解除
    unsetError (str) {
      this.states[str] = true
      this.duplicated = false
      if (str === 'email') {
        this.invalidEmailFormat = false
        this.duplicatedEmail = false
      }
    },
    //
    // 条件変更
    updateConds () {
      this.filled = false
    },
    //
    // 入社経緯、学歴、経験年数から等級号俸を取得
    getInitialCond () {
      if (this.staffData.adoption && this.staffData.graduate && this.staffData.yoe) {
        axios
          .post('/wp-json/jjs/v2/staff/initial-condition', {
            adoption: this.staffData.adoption,
            graduate: this.staffData.graduate,
            yoe: this.staffData.yoe
          })
          .then((response) => {
            this.staffData.salary = response.data.salary
            this.staffData.class = response.data.class
            this.staffData.lv = response.data.lv
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        this.staffData.salary = 0
        this.staffData.class = 1
        this.staffData.lv = 1
      }
    },
    //
    // cls, lvから給与取得
    updateSalary () {
      axios
        .post('/wp-json/jjs/v2/staff/getsalary', {
          cls: this.staffData.class,
          lv: this.staffData.lv
        })
        .then((response) => {
          this.staffData.salary = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  computed: {
    nullCheck () {
      let res = true
      Object.keys(this.states).forEach(el => {
        res *= this.states[el]
      })
      return res
    },
    today () {
      const date = new Date()
      return (date.toJSON().split('T'))[0]
    }
  },
  watch: {
    staffData: {
      handler (newValue) {
      },
      deep: true
    }
  }
}
</script>
