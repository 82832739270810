import axios from 'axios'
import router from '@/router'
axios.defaults.baseURL = 'https://xs041845.xsrv.jp/jjstats'
axios.defaults.headers.common.Authorization = ''

const state = {
  authorized: false,
  authData: JSON.parse(localStorage.getItem('jjAuth')),
  authDataBackup: JSON.parse(localStorage.getItem('jjAuth2')),
  domain: '',
  token: '',
  masterToken: '',
  name: '',
  userId: '',
  capability: ''
}

const getters = {
  authorized: (state) => state.authorized,
  authData: (state) => state.authData,
  authDataBackup: (state) => state.authDataBackup,
  domain: (state) => state.domain,
  token: (state) => state.token,
  masterToken: (state) => state.masterToken,
  name: (state) => state.name,
  userId: (state) => state.userId,
  capability: (state) => state.capability
}

const mutations = {
  authorized (state, authorized) {
    state.authorized = authorized
  },
  authData (state, authData) {
    state.authData = authData
    localStorage.setItem('jjAuth', JSON.stringify(authData))
  },
  authDataBackup (state, authDataBackup) {
    state.authDataBackup = authDataBackup
    localStorage.setItem('jjAuth2', JSON.stringify(authDataBackup))
  },
  domain (state, domain) {
    state.domain = domain
    axios.defaults.baseURL = domain ? `https://xs041845.xsrv.jp/jjstats/${domain}` : 'https://xs041845.xsrv.jp/jjstats'
  },
  token (state, token) {
    state.token = token
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  masterToken (state, masterToken) {
    state.masterToken = masterToken
  },
  name (state, name) {
    state.name = name
  },
  userId (state, userId) {
    state.userId = userId
  },
  capability (state, capability) {
    state.capability = capability
  }
}

const actions = {
  //
  // login
  async login ({ rootGetters, getters, commit }, id) {
    commit('authorized', false)
    commit('domain', rootGetters.slug)
    axios.defaults.headers.common.Authorization = ''
    console.log(axios.defaults.baseURL)
    console.log('try login')
    return new Promise((resolve) => {
      let processing = rootGetters.processing
      commit('processing', processing + 1, { root: true })
      axios
        .post('/wp-json/jwt-auth/v1/token/', getters.authData)
        .then((response) => {
          console.log(response.data)
          commit('token', response.data.token)
          commit('userId', response.data.user_id)
          commit('capability', response.data.capability)
          commit('belong', response.data.belong, { root: true })
          commit('authorized', true)

          if (response.data.capability === 'superadmin') {
            commit('name', '')
          } else {
            commit('name', response.data.user_display_name)
          }

          if ((response.data.belong).length === 1) {
            commit('domain', response.data.slug)
            commit('slug', response.data.slug, { root: true })
          }

          if (response.data.current_blog_details.blog_id !== '1') {
            // commit('domain', response.data.slug)
            // commit('slug', response.data.slug, { root: true })
            commit('companyname', response.data.current_blog_details.blogname, { root: true })
            commit('blogid', response.data.current_blog_details.blog_id, { root: true })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          // commit('processing', false, { root: true })
          processing = rootGetters.processing
          commit('processing', processing - 1, { root: true })
          if (getters.authDataBackup) {
            // 仮ログイン状態フラグtrue
            commit('isTmpLogin', true, { root: true })
          }
          if (id) {
            router.push({ name: 'StaffDetail', params: { id: id, dom: getters.domain } })
          }
        })
    })
  },
  //
  // 特定社員としてログイン
  loginAs ({ rootGetters, getters, commit, dispatch }) {
    // authorized状態OFF
    commit('authorized', false)
    // 現在のauth dataを保存
    commit('authDataBackup', getters.authData)
    // 特定社員のauth dataをセット
    const staffData = rootGetters['Staff/staffData']
    commit('authData', {
      username: staffData.email,
      password: staffData.password
    })
    // 特定社員としてログイン
    dispatch('login')
    // 仮ログイン状態フラグtrue
    commit('isTmpLogin', true, { root: true })
  },
  //
  // 元のログイン状態を復元
  restoreLogin ({ getters, commit, dispatch }) {
    const userid = getters.userId
    // auth data backupが存在するか
    if (getters.authDataBackup) {
      // authorized状態OFF
      commit('authorized', false)
      commit('authData', getters.authDataBackup)
      commit('authDataBackup', '')
      dispatch('login', userid)
      // 仮ログイン状態フラグfalse
      commit('isTmpLogin', false, { root: true })
    }
  },
  //
  // logout
  logout ({ getters, commit }) {
    if (getters.authDataBackup) {
      commit('authData', getters.authDataBackup)
      commit('authDataBackup', '')
    }
    // localStorage.removeItem('masterToken')
    commit('token', '')
    commit('name', '')
    commit('userId', '')
    commit('capability', '')
    commit('authorized', false)
    router.push({ name: 'Login' })
  }
}

const Auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Auth
