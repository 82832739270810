<template>
  <div id="modal" @click="offModal">
    <AddStaff v-if="content === 'add Staff'"></AddStaff>
    <SetRange
      v-if="content === 'set evaluation range'"
      @close="offModal"
    ></SetRange>
  </div>
</template>

<script>
import SetRange from '@/components/Modal/SetRange.vue'
import AddStaff from '@/components/Modal/AddStaff.vue'
export default {
  components: {
    SetRange,
    AddStaff
  },
  data () {
    return {
      content: ''
    }
  },
  mounted () {
    this.content = this.$store.getters['Modal/content']
    this.$store.watch(
      (state, getters) => getters['Modal/content'],
      (newValue) => {
        this.content = newValue
        console.log(newValue)
      }
    )
  },
  methods: {
    offModal () {
      this.$store.commit('Modal/content', '')
      this.$store.commit('Modal/show', false)
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
