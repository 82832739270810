import axios from 'axios'

const state = {
  data: '',
  evalDefs: {},
  evalDefsBonus: {},
  raiseDefs: {},
  bonusDef: {}
}
const getters = {
  data: (state) => state.data,
  evalDefs: (state) => state.evalDefs,
  evalDefsBonus: (state) => state.evalDefsBonus,
  raiseDefs: (state) => state.raiseDefs,
  bonusDef: (state) => state.bonusDef

}
const mutations = {
  data (state, data) {
    state.data = data
    console.log(data)
  },
  evalDefs (state, evalDefs) {
    state.evalDefs = evalDefs
  },
  evalDefsBonus (state, evalDefsBonus) {
    state.evalDefsBonus = evalDefsBonus
  },
  raiseDefs (state, raiseDefs) {
    state.raiseDefs = raiseDefs
  },
  bonusDef (state, bonusDef) {
    state.bonusDef = bonusDef
  }
}
const actions = {
  //
  // 定義読み込み
  async loadDef ({ commit }, key) {
    console.log(`load def ${key}`)
    return new Promise((resolve) => {
      axios
        .get(`/wp-json/jjs/v3/def/${key}`)
        .then((response) => {
          console.log(response.data)
          commit('data', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  //
  // 全定義読み込み
  async loadWholeDefs ({ commit }) {
    console.log('load whole defs')
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v3/def/all')
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    })
  },
  //
  // クラス定義保存
  async saveClassDef ({ getters, commit, dispatch }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/class/update/', {
          classes: getters.data.classDefs,
          classesSP: getters.data.classDefsSP,
          slogans: getters.data.classSlogans,
          slogansSP: getters.data.classSlogansSP
        })
        .then((response) => {
          console.log(response.data)
          // const clientData = Object.assign({}, rootGetters['Client/clientInfo'])
          // clientData.settings.classdefs = getters.classDefs
          // clientData.settings.classSlogan = getters.classSlogans
          // commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Company/loadData', null, { root: true })
        })
    })
  },
  //
  // 評価項目(賞与)保存
  //
  async saveEvalDefsBonus ({ commit, getters, rootGetters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/evalbonus/update', getters.evalDefsBonus)
        .then((response) => {
          console.log(response.data)
          const clientData = rootGetters['Client/clientData']
          clientData.settings.evalDefsBonus = getters.evalDefsBonus
          commit('Client/clientData', clientData, { root: true })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇給定義読込
  async loadRaiseDefs ({ rootGetters, getters, commit }, no) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/raise')
        .then((response) => {
          if (response.data) {
            commit('raiseDefs', response.data)
          } else {
            // 存在しない場合初期値からロード
            const result = []
            const raiseDef = rootGetters['Initial/rankDefs']
            const classDef = rootGetters['Initial/classDefs']
            const found = classDef.find(el => el.no === no)
            Object.keys(found.raise).forEach(el => {
              const raise = raiseDef.find(el2 => el2.rank === el)
              result.push({
                grade: el,
                arr: found.raise[el],
                score: raise.score
              })
            })
            console.log(result)
            commit('raiseDefs', result)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与定義読み込み
  async loadBonusDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .get('/wp-json/jjs/v2/def/bonus')
        .then((response) => {
          // console.log(response.data)
          if (response.data) {
            commit('bonusDef', response.data)
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 賞与定義保存
  async saveBonusDef ({ commit, getters }) {
    return new Promise((resolve) => {
      commit('processing', true, { root: true })
      axios
        .post('/wp-json/jjs/v2/def/bonus/update/', getters.bonusDef)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Defs = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Defs
