import axios from 'axios'

const state = {
  companyList: sessionStorage.getItem('companyList'),
  companySummary: {},
  staffList: [],
  promoteList: []
}

const getters = {
  companyList: (state) => state.companyList,
  companySummary: (state) => state.companySummary,
  staffList: (state) => state.staffList,
  promoteList: (state) => state.promoteList
}

const mutations = {
  companyList (state, companyList) {
    state.companyList = companyList
    localStorage.setItem('companyList', JSON.stringify(companyList))
  },
  companySummary (state, companySummary) {
    state.companySummary = companySummary
  },
  staffList (state, staffList) {
    state.staffList = staffList
  },
  promoteList (state, promoteList) {
    state.promoteList = promoteList
  }
}

const actions = {
  //
  // 管理対象企業リスト
  loadCompanyList ({ commit }) {
    axios
      .get('/wp-json/jjs/v2/owner/companylist')
      .then((response) => {
        // console.log(response.data)
        commit('companyList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  //
  // 賞与確定
  async payBonus ({ getters, commit }, notice) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/owner/paybonus', {
          data: getters.staffList,
          notice: notice
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇格承認
  async approvePromote ({ getters, commit }, notice) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/owner/approve-promote', {
          data: getters.staffList,
          notice: notice
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇給承認
  async approveRaise ({ getters, commit }, notice) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .post('/wp-json/jjs/v2/owner/approve-raise', {
          data: getters.staffList,
          notice: notice
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // 昇格対象スタッフ読み込み
  async loadStaffListPromote ({ getters, commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/owner/promotelist')
        .then((response) => {
          // console.log(response.data)
          commit('promoteList', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  },
  //
  // スタッフ情報更新
  async updateStaffData ({ commit, dispatch }, data) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .patch('/wp-json/jjs/v2/master/staff', data)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Company/loadData', null, { root: true })
        })
    })
  },
  //
  // スタッフ削除
  async deleteStaffData ({ commit, dispatch }, userid) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .delete('/wp-json/jjs/v2/master/staff/' + userid)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Company/loadData', null, { root: true })
        })
    })
  },
  //
  // スタッフ完全削除
  async forceDelete ({ commit, dispatch }, userid) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .delete('/wp-json/jjs/v2/master/staff/force' + userid)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Company/loadData', null, { root: true })
        })
    })
  },
  //
  // スタッフ復活
  async reviveStaffData ({ commit, dispatch }, id) {
    console.log('retrive staff')
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/master/staff/revive/' + id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
          dispatch('Company/loadData', null, { root: true })
        })
    })
  },
  //
  // ダッシュボード表示用データ取得
  async loadSummary ({ commit }) {
    commit('processing', true, { root: true })
    return new Promise((resolve) => {
      axios
        .get('/wp-json/jjs/v2/owner/summary')
        .then((response) => {
          console.log(response.data)
          commit('companySummary', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          commit('processing', false, { root: true })
        })
    })
  }
}

const Owner = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Owner
