import { createStore } from 'vuex'
import Initial from './initial.js'
import Auth from './auth.js'
import Company from './company.js'
import Owner from './owner.js'
import Staff from './staff.js'
import Defs from './defs.js'
import Modal from './modal.js'
import SelfExam from './selfexam.js'
import Jirei from './jirei.js'
import Manager from './manager.js'
import Eval from './eval.js'
import OneOnOne from './oneOnOne.js'

export default createStore({
  state: {
    lastupdate: '',
    processing: 0,
    updated: false,
    pageTitle: '',
    titleAdd: '',
    titleStatus: '',
    slug: '',
    blogid: '',
    companyname: '',
    belong: {},
    mode: '',
    adminMode: false,
    isTmpLogin: false,
    showError: false,
    errorMsg: '',
    evalmode: sessionStorage.getItem('evalmode')
  },
  getters: {
    lastupdate: (state) => state.lastupdate,
    processing: (state) => state.processing,
    updated: (state) => state.updated,
    pageTitle: (state) => state.pageTitle,
    titleAdd: (state) => state.titleAdd,
    titleStatus: (state) => state.titleStatus,
    slug: (state) => state.slug,
    blogid: (state) => state.blogid,
    companyname: (state) => state.companyname,
    belong: (state) => state.belong,
    mode: (state) => state.mode,
    adminMode: (state) => state.adminMode,
    isTmpLogin: (state) => state.isTmpLogin,
    showError: (state) => state.showError,
    errorMsg: (state) => state.errorMsg,
    evalmode: (state) => state.evalmode
  },
  mutations: {
    lastupdate (state, lastupdate) {
      state.lastupdate = lastupdate
    },
    processing (state, processing) {
      state.processing = processing
    },
    updated (state, updated) {
      state.updated = updated
    },
    pageTitle (state, pageTitle) {
      state.pageTitle = pageTitle
    },
    titleAdd (state, titleAdd) {
      state.titleAdd = titleAdd
    },
    titleStatus (state, titleStatus) {
      state.titleStatus = titleStatus
    },
    slug (state, slug) {
      state.slug = slug
    },
    blogid (state, blogid) {
      state.blogid = blogid
    },
    companyname (state, companyname) {
      state.companyname = companyname
    },
    belong (state, belong) {
      state.belong = belong
    },
    mode (state, mode) {
      state.mode = mode
    },
    adminMode (state, adminMode) {
      state.adminMode = adminMode
    },
    isTmpLogin (state, isTmpLogin) {
      state.isTmpLogin = isTmpLogin
    },
    showError (state, showError) {
      state.showError = showError
    },
    errorMsg (state, errorMsg) {
      state.errorMsg = errorMsg
    },
    evalmode (state, evalmode) {
      state.evalmode = evalmode
      sessionStorage.setItem('evalmode', evalmode)
    }
  },
  actions: {
    ShowError ({ commit }, msg) {
      commit('showError', true)
      commit('errorMsg', msg)
    }
  },
  modules: {
    Initial,
    Auth,
    Company,
    Owner,
    Staff,
    Defs,
    Modal,
    SelfExam,
    Jirei,
    Manager,
    Eval,
    OneOnOne
  }
})
