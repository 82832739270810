<template>
  <div>
    <div v-if="capability === 'superadmin' || capability === 'owner'">
      <CompanyDashboardOps :mode="mode" v-if="$route.name === 'CompanyDashboard' || $route.name === 'CompanyDefs' || $route.name === 'CompanySettings'" />
      <StaffEvalGroupOps :mode="mode" v-if="$route.name === 'StaffGroup'" />
      <StaffMasterOps v-if="$route.name === 'StaffMaster' || $route.name === 'BulkRegistration'" />
    </div>
    <div v-if="capability === 'staff' || capability === 'manager'">
      <StaffOps :mode="mode" />
    </div>
    <div v-if="capability === 'manager'">
      <ManagerOps />
    </div>
  </div>
</template>

<script>
import CompanyDashboardOps from '@/components/Nav/CompanyDashboardOps.vue'
import StaffEvalGroupOps from '@/components/Nav/StaffEvalGroupOps.vue'
import StaffMasterOps from '@/components/Nav/StaffMasterOps.vue'
import ManagerOps from '@/components/Nav/ManagerOps.vue'
import StaffOps from '@/components/Nav/StaffOps.vue'
export default {
  components: {
    CompanyDashboardOps,
    StaffEvalGroupOps,
    StaffMasterOps,
    ManagerOps,
    StaffOps
  },
  props: ['mode'],
  data () {
    return {
      capability: this.$store.getters['Auth/capability']
    }
  },
  mounted () {
    if (this.$store.getters['Auth/capability']) {
      this.capability = this.$store.getters['Auth/capability']
    }
    this.$store.watch(
      (state, getters) => getters['Auth/capability'],
      (newValue, oldValue) => {
        this.capability = newValue
      }
    )
  },
  methods: {
    setMode (str) {
      this.$store.commit('mode', str)
    }
  },
  computed: {
  },
  watch: {
  }
}
</script>
